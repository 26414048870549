import PropTypes from 'prop-types';
import Star from './star-full-half.svg';

/**
 * Render 1 star for ratings
 * @param {array} props (float rating)
 * @returns star rating
 */
export default function Rating(props) {
	const { rating } = props;

	const avgRating = rating > 0 ? parseFloat(rating).toFixed(1) : '';
	const showRating = rating > 0 ? '' : 'rating-average-hide';

	const ariaLabel = rating === 0 ? 'There is no rating for this recipe yet.' : `Average rating ${avgRating} out of 5 stars`;

	/**
	 * Apply 'rate' class to div to show hover effect and red color
	 */
	return (
		<div className={`rating ${showRating}`} data-rating={rating} role="img" aria-label={ariaLabel}>
			<Star className="star rating-full" />
			<p className="rating-average">{avgRating}</p>
		</div>
	);
}

Rating.propTypes = {
	rating: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
};
