/// Helper functions. ///
import globalValues from '../static-data/global-values.json';

const { cesVmsGeneralApiEndpoint: vmsContentServicesApiEndpoint } = globalValues?.data?.corusVmsSettings;

function compileVmsParams(params) {
	const queryParams = new URLSearchParams();

	if (!params.limit) {
		queryParams.append('limit', 1);
	}

	// eslint-disable-next-line no-restricted-syntax
	for (const [propKey, propValue] of Object.entries(params)) {
		if (Array.isArray(propValue)) {
			// Multiple values provided - handle accordingly.
			if (propKey.endsWith('__in') || propKey === 'resource_types') {
				// Special case 'OR' handling: pass unioned by `__`.
				queryParams.append(propKey, propValue.join('__'));
			} else {
				// Standard 'OR' handling: pass each as a separate param.
				propValue.forEach((value) => {
					queryParams.append(propKey, value);
				});
			}
		} else if (propValue !== undefined && propValue !== null && propValue !== '') {
			// Single value provided.
			queryParams.append(propKey, propValue);
		}
	}

	return queryParams;
}

function createQueryString(query, items) {
	const resultValues = items.map((item) => item[query.key] || item.data[query.key]);
	return resultValues.join(query.join);
}

const https = require('https');

/**
 * Load data from the VMS API from a given URL.
 * Expected template: {base_vms_url}/api/container/v1/?key=value&...
 *
 * Returns a promise for data or an error.
 */
export function queryVmsUrl(vmsUrl) {
	const url = new URL(vmsContentServicesApiEndpoint);
	const baseVmsUrl = url.origin;
	const vmsUrlObject = new URL(vmsUrl.replace('{base_vms_url}', baseVmsUrl));

	/* Request has been deprecated - use native request */

	const promise = new Promise((resolve, reject) => {
		const options = {
			hostname: vmsUrlObject.host,
			port: 443,
			path: vmsUrlObject.pathname + vmsUrlObject.search,
			method: 'GET',
		};
		const chunks = [];
		const req = https.request(options, (response) => {
			console.log(`statusCode: ${response.statusCode}`);
			response.on('data', (d) => {
				chunks.push(d);
			});
			response.on('end', () => {
				const data = Buffer.concat(chunks);
				resolve(JSON.parse(data));
			});
		});

		req.on('error', (error) => {
			reject(new Error(`VMS request failed: ${error}`));
		});

		req.end();
	});

	return promise;
}

/**
 * Load data from the VMS API from a query container.
 * Expects a query container item (i.e. a child of another collection).
 *
 * Returns a promise for data or an error.
 */
export function resolveQueryContainer(query) {
	if (query.query_type === 'compound') {
		const promise = new Promise((resolve, reject) => {
			let completedQueries = 0;

			const completeQuery = (innerQuery, result) => {
				completedQueries += 1;
				const stringResult = createQueryString(innerQuery, result.results);
				// eslint-disable-next-line no-param-reassign
				query.query_template = query.query_template.replace(`{${innerQuery.name}}`, stringResult);

				if (completedQueries >= query.queries.length) {
					// eslint-disable-next-line no-param-reassign
					query.query_type = 'simple';
					resolveQueryContainer(query).then(
						(queryContainerResult) => resolve(queryContainerResult),
						(error) => reject(error),
					);
				}
			};

			query.queries.forEach((innerQuery) => {
				resolveQueryContainer(innerQuery).then(
					(result) => completeQuery(innerQuery, result),
				);
			});
		});
		return promise;
	}
	return queryVmsUrl(query.query_template);
}

/**
 * Load data from the VMS API using a list of parameters.
 *
 * Returns a promise for data or an error.
 */
export async function queryVms(params) {
	const queryParams = compileVmsParams(params);

	/**
	 * Don't make VMS call if limit is the only query param.
	 * Need this check to synchronously load VMS views.
	 */
	const queryKeys = queryParams.keys();
	if (queryKeys.next().value === 'limit' && queryKeys.next().done) {
		// No params provided. Return an empty result set.
		return null;
	}

	const vmsUrl = `{base_vms_url}/api/container/v1/?${queryParams.toString()}`;
	return queryVmsUrl(vmsUrl);
}
