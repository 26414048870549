import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { AdSlot, DFPManager } from 'react-dfp';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer'; // Polyfill for ios12 and under
import { BreakPoints } from '../Breakpoints/Breakpoints';
import useCurrentWidth from '../../utils/useCurrentWidth';
import { AD_PATH } from './AdsProvider';
import { AdSizes } from './AdSizes';
import { isMoatDataAvailable, setMoatSlotTargeting } from './scripts/MoatScript';
import globalValues from '../../static-data/global-values.json';

const { ceAdsMoatIvtEnabeled, cesDataAdsLabel } = globalValues?.data?.corusAdsSettings;

export default function Ad(props) {
	const {
		sizes, className = '', wrapperClass = '', htmlId = '', adLabel = cesDataAdsLabel || 'ADVERTISEMENT', pos = '1',
	} = props;

	const currentWidth = useCurrentWidth();
	const { ref, inView } = useInView({
		rootMargin: '200px',
		triggerOnce: true,
	});

	const getCurrentBreakpointSize = () => {
		// Find current breakpoint string. Eg 'sm', 'md', etc.
		let currentBreakpoint = 0;
		Object.keys(BreakPoints).forEach((bp) => {
			if (currentWidth > BreakPoints[bp]) {
				currentBreakpoint = bp;
			}
		});

		let currentBreakpointSize = [];
		Object.entries(sizes).forEach((entries) => {
			const bpString = entries[0];
			const size = entries[1];
			const found = bpString.split(' ').some((bp) => bp === currentBreakpoint);
			if (found) {
				currentBreakpointSize = size;
			}
		});
		return currentBreakpointSize;
	};

	let adRequested = false;

	const displayAd = () => {
		if (adRequested) { // Prevents repeating ad render
			return;
		}
		adRequested = true;

		DFPManager.getGoogletag().then((googletag) => {
			if (typeof googletag !== 'undefined') {
				googletag.cmd.push(() => {
					const definedSlots = googletag.pubads().getSlots();
					const slot = definedSlots.find((current) => current.getSlotElementId() === htmlId);
					slot.setTargeting('pos', pos || '1');
					setMoatSlotTargeting(slot);
					const size = getCurrentBreakpointSize();
					if (typeof apstag !== 'undefined' && size !== AdSizes.SPONSOR_LOGO && size !== AdSizes.HIDDEN) {
						// Perform Amazon header bidding
						// eslint-disable-next-line no-undef
						apstag.fetchBids({
							slots: [
								{
									slotID: htmlId,
									sizes: size,
									slotName: `${AD_PATH}/${htmlId}`,
								},
							],
							timeout: 1200,
						}, () => {
							googletag.cmd.push(() => {
								// eslint-disable-next-line no-undef
								apstag.setDisplayBids();
								googletag.pubads().refresh([slot], { changeCorrelator: false });
							});
						});
					} else {
						googletag.pubads().refresh([slot], { changeCorrelator: false });
					}
				});
			}
		});
	};

	useEffect(() => {
		const moatTimeout = 1000; // Total time to wait for moat availability
		const moatInterval = 200; // Time between checking for moat data
		let moatDataAvailableTimeoutId;
		let failsafeTimeoutId;

		// Lazy loading accomplished by using 'react-intersection-observer'
		if (inView) {
			if (!isMoatDataAvailable() && ceAdsMoatIvtEnabeled) {
				// Poll for moat data availability
				moatDataAvailableTimeoutId = setInterval(() => {
					if (isMoatDataAvailable()) {
						// Clear timers and display ad
						clearInterval(moatDataAvailableTimeoutId);
						clearTimeout(failsafeTimeoutId);
						displayAd();
					} // Else moat data is not ready
				}, moatInterval);

				// Set up timeout for moat data
				failsafeTimeoutId = setTimeout(() => {
					clearInterval(moatDataAvailableTimeoutId);
					displayAd(); // Display ad without moat data
				}, moatTimeout);
			} else {
				displayAd();
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	const sizesItems = Object.entries(sizes);

	let allSizes = [];

	const mapping = sizesItems.map((sizeItem) => {
		const bpString = sizeItem[0];
		const bpRange = bpString.split(' ');
		const bp = bpRange[0];
		const size = sizeItem[1];

		if (allSizes.indexOf(size) === -1) {
			allSizes = allSizes.concat(size);
		}

		return { viewport: [BreakPoints[bp], 0], sizes: size };
	});

	return (
		<div ref={ref} className={`ad-wrapper ${wrapperClass}`}>
			<span className="ad-label">{adLabel}</span>
			<AdSlot
				adUnit={htmlId}
				className={`ad-slot ${className}`}
				sizeMapping={mapping}
				sizes={allSizes}
				slotId={htmlId}
			/>
		</div>
	);
}

Ad.propTypes = {
	sizes: PropTypes.object.isRequired,
	className: PropTypes.string,
	wrapperClass: PropTypes.string,
	htmlId: PropTypes.string,
	adLabel: PropTypes.string,
	pos: PropTypes.string,
};
