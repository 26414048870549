import PropTypes from 'prop-types';
import { useContext } from 'react';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import fromVms from '../../../utils/fromVms';
import AppContext from '../../../context/AppContext';

const VideoPlayer = dynamic(
	() => import('../VideoPlayer/VideoPlayer'),
	{ ssr: false },
);
function VmsVideo(props) {
	const {
		id, fallback = null, items = [], loading = false, errorState = false, customConfig = {},
	} = props;

	const { playerVersion, adTargeting } = useContext(AppContext);

	const JwPlayerUrl = `https://videoplayer.smdg.ca/${playerVersion}`;

	let video;

	if (items.length < 1) {
		// Placeholder properties to allow video player to continue construction.
		video = {
			data: {},
			resources: [],
		};
	} else {
		[video] = items;
	}

	// Load the video player scripts without blocking the page rendering process
	const renderScripts = (
		<>
			<Script
				src="https://code.jquery.com/jquery-1.11.1.js"
				id="jquery-1.11.1"
				strategy="afterInteractive"
			/>
			<Script
				src={`${JwPlayerUrl}/ignore/js/omniture.js`}
				id="omniture_script-js"
				strategy="afterInteractive"
			/>
			<Script
				src={`${JwPlayerUrl}/ignore/js/omnitureHelper.js`}
				id="omniture_helper_script-js"
				strategy="afterInteractive"
			/>
			<Script
				src={`${JwPlayerUrl}/videoplayer.min.js`}
				id="ce_videoplayer_script-js"
				strategy="afterInteractive"
			/>
			<Script strategy="lazyOnload">
				{`
					var videoCssLink = document.createElement("link");
					videoCssLink.rel = "stylesheet";
					videoCssLink.href = "${JwPlayerUrl}/corus-theme.css";
					document.head.appendChild(videoCssLink);
				`}
			</Script>
		</>
	);

	return (
		<>
			{(!errorState) ? (
				<div className="article-video">
					{renderScripts}
					{ !loading
						? (
							<VideoPlayer
								videoId={id}
								fullscreenMode={false}
								adTargeting={adTargeting}
								customConfig={customConfig}
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...video}
							/>
						) : null }
				</div>
			)
				: fallback }
		</>
	);
}

VmsVideo.propTypes = {
	id: PropTypes.string.isRequired,
	customConfig: PropTypes.shape({
		autostart: PropTypes.bool,
		divId: PropTypes.number,
	}),
	items: PropTypes.array,
	loading: PropTypes.bool,
	errorState: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.bool,
	]),
	fallback: PropTypes.element,
};

const propMapping = (props) => ({
	guid: props.id,
});

export default fromVms(VmsVideo, propMapping);
